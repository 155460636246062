@import "components/_variables.scss";

.blue_link {
  color: #0000EE;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.required-error {
  color: #da0000;
  padding-left: 10px;
}

.container {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

.container--inner {
  max-width: 1220px;
  padding: 0 10px;

  @include media(xxs) {
    padding: 0;
  }
}

.container--cabinet {
  max-width: 100%;
  padding: 0 10px;

  @include media(xxs) {
    padding: 0;
  }
}


.jcc {
  justify-content: center;
}

.pb-2 {
  padding-bottom: 20px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 30px;
}

.languages {
  height: 35px;
  padding: 20px;
  display: flex;

  &__list {
    display: flex;
  }

  .icon-setup {
    background-image: url(../assets/img/setup.png);
    background-size: contain;
    width: 20px;
    height: 20px;
  }

  & .language {
    padding-right: 15px;
    text-transform: uppercase;
    color: #ffffff;

    &:hover {
      cursor: pointer;
      color: #33d4ed;
    }

    &.active {
      color: #33d4ed;
      font-weight: 700;
      pointer-events: none;
    }

    transition: 0.3s color;
  }

  & .exit {
    padding-right: 30px;
    text-transform: uppercase;
    color: #ffffff;
    transition: 0.3s;

    &:hover {
      color: #33d4ed;
      cursor: pointer;
    }
  }

  @include media(lgs) {
    //display: none;
    padding: 0;
    flex-direction: column;

    .icon-setup {
      margin-top: 20px;
    }

    &__list {
      margin-top: 20px;
    }

    .exit {
      margin-top: 20px;
      color: #145059;
    }

    & .language {
      &:hover {
        font-weight: 700;
        color: #fff;
      }

      &.active {
        color: #fff;
      }
    }

  }

}


.login {
  width: 100%;
  padding: 20px 0;

  .eu_links {
    flex-direction: column;
    align-items: start;
    a {
      margin-bottom: 7px;
    }
  }

  @include media(xxs) {
    padding: 0;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  }

  &__item {
    width: 50%;
    @include media(md) {
      width: 100%;
    }

    &--content {
      background-color: #33d4ed;
      padding: 110px 70px;
      @include media(lgsm) {
        padding: 60px 50px;
      }
      @include media(md) {
        padding: 60px 20px;
      }
    }

    &--data {
      background-color: #fff;
      padding-top: 115px;
      @include media(md) {
        order: -1;
      }
    }

    &--block {
      padding-top: 72px;
    }
  }

  &__languages {
    .languages {
      flex-direction: row;
      padding: 0;

      .icon-setup {
        display: none;
      }

      .exit {
        margin-top: 0;
      }

      @include media(xxs) {
        justify-content: center;
      }
    }

    .languages__list {
      margin-top: 0;

      .active {
        color: #33d4ed;
      }
    }

    display: flex;
    padding: 19px 115px 0 115px;


    .language {
      color: #000;

      &:hover {
        font-weight: 400;
        color: #33d4ed;
      }
    }
  }

  &__form {
    padding: 20px 115px 82px 115px;

    .wrong-fields {
      font-size: 16px;
      padding-bottom: 15px;
      color: #da0000;
    }

    .textfield--focused {

    }

    .input__holder {
      position: relative;
      padding-top: 30px;
      padding-bottom: 15px;

      input {
        display: block;
        width: 100%;
        outline: none;
        padding: 6px 6px 6px 20px;
        margin-top: -3px;
        border: none;
        border-bottom: 1px solid #333;
        transition: .3s;
        box-shadow: inset 0 0 0 25px #fff;
      }

      label {
        position: absolute;
        top: 15px;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        line-height: 1;
        color: #333;
        padding-left: 16px;
        font-weight: 400;
      }

      :focus + .input-name {
        top: 15px;
        font-size: 12px;
        transition: .3s;
        color: #333;
      }
    }

    @include media(lgsm) {
      padding: 60px 75px;
    }
    @include media(md) {
      padding: 60px 20px;
    }
  }


  &__title {
    font-size: 30px;
    line-height: 1.2;
    color: #ffffff;
    font-weight: 400;
    height: 117px;
    overflow: hidden;
    padding-bottom: 45px;
  }

  &__text {
    font-size: 16px;
    line-height: 1.9;
    color: #ffffff;
    font-weight: 400;
    padding-bottom: 46px;
    height: 285px;
    overflow: hidden;
  }

  &__slider {
    position: relative;
  }

  &__dots {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0px -3px;
    padding-right: 75px;
    padding-top: 30px;

    @include media(xs) {
      left: 50%;
      transform: translate(-20%, -30%);
    }
  }

  &__dot {
    span {
      cursor: pointer;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #333333;
      border: 2px solid #333333;
      margin: 0 5px;
      transition: 0.3s background-color, 0.3s border-color, 0.3s width, 0.3s height;
    }

    &--active {
      span {
        width: 16px;
        height: 16px;
        border: 2px solid #333333;
        background-color: #fff;
      }
    }
  }

  &__block {
    padding: 0 82px;
    @include media(lgsm) {
      padding: 0 55px;
    }
    @include media(md) {
      padding: 0 20px;
    }
  }

  &__back {
    cursor: pointer;
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0px;
    margin-bottom: 30px;

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 3px;
      transition: 0.3s background-color, 0.3s border-color;
    }

    &:after {
      top: calc(50% - 1px);
      width: 26px;
      height: 2px;
      background-color: #33d4ed;
    }


    &:before {
      top: calc(50% - 7px);
      width: 14px;
      height: 14px;
      border-bottom: 2px solid #33d4ed;
      border-left: 2px solid #33d4ed;
      transform: rotate(45deg);
    }

    &:hover {
      opacity: 0.5;
    }

    transition: 0.3s;
  }

  &__subtitle {
    font-size: 36px;
    line-height: 1;
    color: #333333;
    text-transform: uppercase;
    font-weight: 200;
    @include media(lgsm) {
      font-size: 31px;
    }
    @include media(md) {
      font-size: 23px;
    }

    &--in {
      padding: 0 100px;
      @include media(lgsm) {
        padding: 0 75px;
      }
      @include media(md) {
        padding: 0 20px;
      }
    }

    @include media(xxs) {
      text-align: center;
    }
  }

  &__btn {
    font: inherit;
    cursor: pointer;
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 1.3;
    color: #fff;
    background-color: #33d4ed;
    border: none;
    border-radius: 20px 0px 20px 20px;
    outline: none;
    text-transform: uppercase;
    padding: 11px;
    margin-top: 15px;
    transition: 0.3s background-color;

    &:disabled {
      background-color: #2d8390;
    }

    &:hover {
      background-color: #145059;
    }

    @include media(md) {
      max-width: 380px;
    }
  }

  &__timer {
    font-size: 14px;
    text-transform: none;
  }

  &__inner {
    display: flex;
    align-items: center;
    padding-top: 40px;

    & a {
      padding-right: 5px;
    }
  }

  &__box {
    font-size: 16px;
    line-height: 1.5;

    &--question {
      color: #333333;
      margin-right: 5px;
    }

    &--answer {
      color: #33d4ed;
      background-color: inherit;
      cursor: pointer;
      border: none;

      transition: 0.3s color;

      &:hover {
        color: #145059;
      }
    }

    @include media(xxs) {
      text-align: center;
    }
  }
}


.inner-box {
  max-height: calc(100vh - 170px);
  overflow: auto;
  @include media(xs) {
    max-height: none;
    overflow: visible;
  }

  & .account {
    padding: 20px 25px 25px 25px;

    &__need {
      font-size: 18px;
      line-height: 1.3;
      color: #ff6a6a;
      text-transform: uppercase;
      padding-top: 32px;
      padding-bottom: 30px;
      @include media(mini) {
        font-size: 16px;
      }
    }

    &__box {
      padding-bottom: 26px;
    }

    &__name {
      font-size: 14px;
      line-height: 1.2;
      color: #808080;
      text-transform: uppercase;
      padding-bottom: 8px;
    }

    &__desc {
      font-size: 16px;
      line-height: 1.5;
      color: #141414;

      & span {
        padding-right: 5px;
      }
    }
  }

  .plan {
    padding: 15px 25px 10px 25px;

    &__title {
      font-size: 16px;
      line-height: 1.2;
      color: #141414;
      text-transform: uppercase;
      padding-top: 25px;
      padding-bottom: 20px;
      font-weight: 200;
    }

    &__rate {
      font-size: 36px;
      line-height: 1;
      color: #141414;
      text-transform: uppercase;
      padding-bottom: 35px;
      font-weight: 200;
    }

    &__text {
      font-size: 16px;
      line-height: 1.5;
      color: #79b12c;
      text-transform: uppercase;
      font-weight: 200;
    }

    &__price {
      font-size: 16px;
      line-height: 1.5;
      color: #79b12c;
      text-transform: uppercase;
      padding-bottom: 40px;
      font-weight: 200;

      span {
        display: inline-block;
        font-size: 36px;
        line-height: 1;
        padding-top: 12px;
        margin-right: 5px;
      }
    }

    &__sum {
      font-size: 16px;
      line-height: 1.5;
      color: #141414;
      font-weight: 200;
      text-transform: uppercase;
      padding-bottom: 32px;

      b {
        font-weight: 400;
        padding: 0 15px;
      }
    }

    &__slider {
      margin-bottom: 55px;
    }

    &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 32px;
    }

    &__arrow {
      cursor: pointer;
      display: block;
      width: 20px;
      height: 20px;
      border: none;
      outline: none;
      background-color: #ffffff;
      position: relative;

      &:after {
        position: absolute;
        content: ' ';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 3px;
        background-color: #c1c1c1;
      }

      &--plus {
        &:before {
          position: absolute;
          content: ' ';
          top: 0px;
          left: 50%;
          transform: translateX(-50%);
          width: 3px;
          height: 20px;
          background-color: #c1c1c1;
        }
      }
    }

    &__cost {
      position: relative;

      input[type=range] {
        -webkit-appearance: none;
        margin: 18px 0;
        width: 100%;
        cursor: pointer;
        background-color: #c5c5c5;
        height: 3px;

      }

      input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: 2px solid #75af25;
        height: 21px;
        width: 21px;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
      }
    }

    &__line {
      height: 2px;
      background-color: #c5c5c5;
    }

    &__handle {
      cursor: pointer;
      position: absolute;
      display: block;
      top: calc(50% - 10px);
      left: 0px;
      width: 20px;
      height: 20px;
      box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.2);
      border: 2px solid #75af25;
      background-color: #fff;
      border-radius: 50%;
    }

    &__block {
      display: flex;
      justify-content: space-between;
      padding-top: 32px;
    }

    &__data {
      font-size: 10px;
      line-height: 1.2;
      color: #333333;
      text-transform: uppercase;

      span {
        font-size: 14px;
      }
    }

    .terms__btn {
      margin-top: 30px;
      text-align: center;
    }

    .c__form_approve0 {

      td > input {
        cursor: pointer;
        transform: scale(2);
        padding: 10px;
        margin-left: 7px;
      }
    }

    &__btn {
      font: inherit;
      cursor: pointer;
      display: block;
      width: 100%;
      font-size: 16px;
      line-height: 1.5;
      color: #ffffff;
      background-color: #79b12c;
      border: none;
      outline: none;
      text-transform: uppercase;
      border-radius: 5px;
      padding: 9px;
      transition: 0.3s background-color;

      &:hover {
        background-color: #3d5916;
      }

      //@include media(sm) {
      //  max-width: 360px;
      //}
    }

    @include media(sm) {
      padding: 15px 15px 10px 15px;
    }
  }


  .payment {
    padding: 0 25px 35px 25px;

    &__holder {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(20, 20, 20, 0.2);
      padding-top: 10px;
      padding-bottom: 16px;
    }

    &__acc-balance {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      color: #ababab;

    }

    &__user-info-block {
      margin: 25px 0 5px 0;
    }

    &__acc-info {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      margin-bottom: 20px;
      color: #ababab;
    }

    &__sum {
      margin-top: 10px;
      font-size: 36px;
      color: #141414;
      text-transform: lowercase;
    }

    &__user-info {
      margin-top: 10px;
      color: #141414;
      text-transform: lowercase;
    }

    &__currency {
      font-size: 14px;
    }

    &__content,
    &__block {
      margin-bottom: 15px;
    }

    &__content {
      padding-right: 14px;
    }

    &__balance {
      font-size: 16px;
      line-height: 1.5;
      color: #141414;
      text-transform: uppercase;
      font-weight: 200;
    }

    &__price {
      font-size: 14px;
      color: #141414;
      font-weight: 200;
      padding-top: 10px;

      span {
        font-size: 36px;
      }
    }

    &__block {
      max-width: 115px;
    }

    &__btn {
      cursor: pointer;
      font: inherit;
      display: none;
      font-size: 14px;
      line-height: 1.5;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      border: 1px solid red;
      background-color: #ff6a6a;
      border-radius: 5px;
      padding: 7px;
      transition: 0.3s color;

      @include media(lgs) {
        display: block;
      }

      @include media(xs) {
        display: block;
      }

      @include media(mini) {
        display: block;
      }

      &:hover {
        color: #1b592c;
      }
    }

    &__need {
      font-size: 12px;
      line-height: 2;
      color: #141414;
      text-transform: uppercase;
      padding-top: 15px;

      span {
        text-transform: none;
      }
    }

    &__wrap {
      padding-top: 10px;

      .setting__msg {
        margin-bottom: 20px;
      }

      & .load-more {
        font-size: 12px;
        text-transform: uppercase;
        padding-top: 20px;
        color: #33d4ed;

        &:hover {
          cursor: pointer;
        }
      }

      .btn {
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        color: #ffffff;
        border: none;
        outline: none;
        text-transform: uppercase;
        border-radius: 5px;
        padding: 9px 20px;
        transition: 0.3s background-color;
      }

      .btn-success {
        background-color: #79b12c;

        &:hover {
          background-color: #3d5916;
        }
      }

      .btn-danger {
        margin-right: 10px;
        background-color: #ff6a6a;

        &:hover {
          background-color: #ce4b44;
        }
      }
    }

    &__item {
      border-top: 2px solid #808080;
      background-color: #ffffff;
      padding: 0 15px 20px 15px;
      padding-bottom: 18px;
      margin-top: 30px;

      &.PROCESSING {
        & .payment__status {
          & span.PROCESSING {
            color: #e4c800;
            display: block;
          }
        }
      }

      &.APPROVED {
        color: #3dce66;
        border-top: 2px solid #3dce66;
      }

      &.PENDING {
        color: #3dce66;
        border-top: 2px solid #3dce66;
      }

      &.FAIL {
        color: #ce4b44;
        border-top: 2px solid #ce4b44;
      }

      &.DECLINED {
        color: #ce4b44;
        border-top: 2px solid #ce4b44;
      }

      &.REFUNDED {
        color: #ce4b44;
        border-top: 2px solid #ce4b44;
      }

      @include media(sm) {
        padding: 15px;
      }
    }

    &__inner {
      display: flex;
      flex-wrap: wrap;

      .payment__box {
        padding: 20px 20px 0 0;
      }

      .payment__box:last-child {
        padding-right: 0;
      }
    }

    &__title {
      font-size: 12px;
      line-height: 1.2;
      color: #ababab;
      text-transform: uppercase;
      padding-bottom: 6px;
    }

    &__data {
      font-size: 16px;
      line-height: 1.5;
      color: #141414;
      font-weight: 200;
    }

    &__status {
      font-size: 16px;
      line-height: 1.5;
      color: #3dce66;
      font-weight: 200;
      text-transform: uppercase;

      .APPROVED {
        color: #3dce66;
        //border-top: 2px solid #3dce66;
      }

      .CREATED {
        color: #e4c800;
      }

      .PENDING {
        color: #3dce66;
        //border-top: 2px solid #3dce66;
      }

      .FAIL {
        color: #ce4b44;
        //border-top: 2px solid #ce4b44;
      }

      .DECLINED {
        color: #ce4b44;
        //border-top: 2px solid #ce4b44;
      }

      .REFUNDED {
        color: #ce4b44;
        //border-top: 2px solid #ce4b44;
      }
    }

    &__count {
      font-size: 16px;
      line-height: 1.2;
      color: #141414;
      font-weight: 200;
    }
  }
}


.cabinet {
  width: 100%;
  padding: 20px 0;

  @include media(xxs) {
    padding: 0;
  }

  &__hide {
    display: none;
  }

  .languages {
    margin-bottom: 10px;
  }

  & .container {
    &.container--cabinet {
      width: 1250px;

      @include media(lgs) {
        width: auto;
      }

      @include media(xs) {
        width: auto;
      }

      @include media(mini) {
        width: auto;
      }

    }
  }

  &__inner {
    display: flex;
    position: relative;

    @include media(lgs) {
      min-height: 350px;
    }
  }

  &__main {
    @include media(smdx) {
      width: 100%;
    }
  }

  &__block {
    display: none;

    .main-nav__toggle {
      display: none;
      position: relative;
      cursor: pointer;
      width: 30px;
      height: 30px;
      margin: 0 auto;
      margin-top: 15px;

      .line-burger {
        cursor: pointer;
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 30px;
        height: 2px;
        background-color: #ffffff;
        transition: 0.5s width;
      }

      .line-burger:after, .line-burger:before {
        content: "";
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: #ffffff;
        left: 0;
        transition: 0.5s;
      }

      .line-burger:after {
        top: calc(100% + 8px);
      }

      .line-burger:before {
        bottom: calc(100% + 8px);
      }
    }


    @include media(lgs) {
      display: block;
      width: 250px;
      border-top: 4px solid #2cbcd2;
      background-color: #33d4ed;
    }

    @include media(xs) {
      width: 65px;

      .main-nav__toggle {
        display: block;
      }

    }

    @include media(mini) {
      width: 60px;
    }

  }

  &__list {
    padding: 34px 25px;

    li {
      cursor: pointer;
    }

    @include media(xs) {
      display: none;
    }

  }

  &__link {
    display: inline-block;
    font-size: 16px;
    line-height: 1.2;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 15px;
    transition: 0.3s box-shadow;

    &:hover {
      text-shadow: 0px 0.5px 0px #ffffff, 0.5px 0px 0px #ffffff, 0px -0.5px 0px #ffffff, -0.5px 0px 0px #ffffff;
    }

    &--active {
      text-shadow: 0px 0.5px 0px #ffffff, 0.5px 0px 0px #ffffff, 0px -0.5px 0px #ffffff, -0.5px 0px 0px #ffffff;
    }

    &.logout {
      padding-top: 20px;
      color: #145059;
    }

    &.lang {
      padding-top: 20px;
      display: flex;

      & .language {
        padding-right: 15px;
        text-transform: uppercase;
        color: #ffffff;

        &:hover {
          cursor: pointer;
        }

        &.active {
          font-weight: 700;
          pointer-events: none;
        }
      }
    }
  }

  .terms {
    max-width: 759px;
    padding: 0 10px;

    .languages {
      flex-direction: inherit;

      &__list {
        //margin-top: 20px;
      }
    }
  }

  .terms__item {
    width: 100%;
    background-color: #fdfdfd;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;

    @include media(lgs) {
      width: calc(100% - 250px);
    }

    @include media(xs) {
      width: calc(100% - 65px);
    }

    @include media(mini) {
      width: calc(100% - 60px);
    }

    .cabinet__item:nth-child(odd) {
      background-color: #f6f4fc;
    }

    .cabinet__item:nth-child(even) {
      background-color: #fdfdfd;
    }

  }

  &__item {
    width: 33.3%;
    //  padding: 35px 25px;
    @include media(lgs) {
      display: none;
      width: 360px;
    }

    @include media(xxs) {
      display: none;
      width: 100%;
    }

    @include media(mini) {
      //width: 250px
    }

    &--active {
      @include media(lgs) {
        display: block;
      }
    }
  }


  &__holder {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid rgba(20, 20, 20, 0.2);
    padding: 35px 0 25px 0;
    margin: 0 25px 0 25px;
  }

  &__title {
    font-size: 24px;
    line-height: 1;
    color: #141414;
    text-transform: uppercase;
    font-weight: 200;
    margin-top: -2px;
    @include media(mini) {
      font-size: 19px;
    }
  }

  &__active {
    max-width: 105px;
    width: 100%;
    font-size: 16px;
    line-height: 1.1;
    color: #79b12c;
    text-transform: uppercase;
    text-align: right;
    margin-left: auto;

    &.inactive {
      color: #ff6a6a;
      max-width: 120px;
      padding-top: 15px;
    }

    span {
      font-size: 14px;
      line-height: 1;
    }
  }


}

.popup {
  display: none;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 10px;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 20;
  transition: 0.3s opacity, 0.3s visibility;
  @include media(xs) {
    display: flex;
  }

  &--active {
    opacity: 1;
    visibility: visible;
  }

  &__block {
    transform: translateX(-135%);
    max-width: 250px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 25px;
    z-index: 2;
    background-color: #33d4ed;
    border-top: 4px solid #2cbcd2;
    transition: 0.3s transform;

    &--active {
      transform: translateX(0);
    }
  }

  &__link {
    display: inline-block;
    font-size: 16px;
    line-height: 1.2;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 15px;
    transition: 0.3s box-shadow;

    &:hover {
      text-shadow: 0px 0.5px 0px #ffffff, 0.5px 0px 0px #ffffff, 0px -0.5px 0px #ffffff, -0.5px 0px 0px #ffffff;
    }

    &--active {
      text-shadow: 0px 0.5px 0px #ffffff, 0.5px 0px 0px #ffffff, 0px -0.5px 0px #ffffff, -0.5px 0px 0px #ffffff;
    }
  }

  &__back {
    cursor: pointer;
    display: block;
    width: 30px;
    height: 30px;
    position: relative;
    border: none;
    outline: none;
    padding: 0px;
    background-color: #33d4ed;
    margin-bottom: 32px;

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 3px;
      transition: 0.3s background-color, 0.3s border-color;
    }

    &:after {
      top: calc(50% - 1px);
      width: 26px;
      height: 2px;
      background-color: #ffffff;
    }


    &:before {
      top: calc(50% - 7px);
      width: 14px;
      height: 14px;
      border-bottom: 2px solid #ffffff;
      border-left: 2px solid #ffffff;
      transform: rotate(45deg);
    }
  }
}


.phone-content {
  font-size: 16px;
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;

  .link {
    color: #33d4ed;
    background-color: inherit;
    cursor: pointer;
    font-size: 16px;
    border: none;
    padding-left: 10px;
    transition: 0.3s color;
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .link:hover {
    color: #145059;
  }

}

.info_table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  height: auto;
}

.info_table, .info_table td, .info_table th {
  border: 1px solid #595959;
}

.info_table td, .info_table th {
  padding: 3px 10px;
  height: 40px;
}

.info_table th {
  background: #347c99;
  color: #fff;
  font-weight: normal;
}

.rates {
  background-color: #fff;
  padding: 60px 40px;
  width: 80%;
  max-width: 1200px;

  .login__subtitle {
    text-align: center;
  }

  @include media(xs) {
    padding: 40px 20px;
    width: 100%;
    font-size: 14px;
  }
}
