html { box-sizing: border-box; scroll-behavior: smooth; }
html, body, #root { height: auto; }


#root {
  margin: 0;
  padding: 0;
  background-image: url('../assets/img/map.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: top center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-x: hidden;
  font-family: "Montserrat";
}

button {
  font-family: "Montserrat";
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}


*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: normal;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}


////////////////// CheckBox STYLES //////////////////

.flexed-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
}

.flexed-box .check-box {
  width: 50px;
  padding-right: 20px;
}

.flexed-box .check-box .checkbox {
  width: 30px;
  height: 30px;
  outline: 2px solid #b3b3b3;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.flexed-box .check-box .checkbox.checked:before {
  content: " ";
  position: absolute;
  padding: 50%;
  background: url('../assets/img/checkmark.svg') 50% no-repeat;
  background-size: 16px 16px;
}


/////////////////////// ALERTIFY JS ///////////////////////////

.alert_link {
  color: #00e;
  text-decoration: underline;
}

.alertify {

  .ajs-header {
    font-family: "Montserrat";
    background-color: #33d4ed;
    color: #fff;
    font-size: 18px;
  }

  .ajs-footer .ajs-buttons.ajs-primary .ajs-button {
    color: #ffffff;
    background-color: #79b12c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

}

